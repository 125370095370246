import React from "react"
// import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Globe, Clock, ExternalLink } from 'react-feather'
import { Tabs, Tab } from "react-bootstrap"
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import WorldMap from "../../components/worldmap"

export default class Denmark extends React.Component {
  constructor(props){
      super(props);

      this.state = {
          showEcosystemsLink: false,
          showNextLink: false,
          showPrevLink: false
      }

      this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event){
      // Esc key press
      if(event.keyCode === 27) {
          this.setState({
              showEcosystemsLink: true
          });

          setTimeout(() => {
              let linkRef = document.getElementById("esc-action");
              linkRef.click();
          }, 50)
      }

      // Left Arrow key press
      if(event.keyCode === 37) {
          this.setState({
              showPrevLink: true
          });

          setTimeout(() => {
              let prevLinkRef = document.getElementById("prev-action");
              prevLinkRef.click();
          }, 50)
      }

      // Right Arrow key press
      if(event.keyCode === 39) {
          this.setState({
              showNextLink: true
          });

          setTimeout(() => {
              let nextLinkRef = document.getElementById("next-action");
              nextLinkRef.click();
          }, 50)
      }
  }
  componentDidMount(){
      document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount(){
      document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
      return (
  <Layout>
            {
            this.state.showEcosystemsLink &&
            <AniLink id="esc-action" fade to="/startup-visas" duration={0.4}></AniLink >
        }
        {
            this.state.showPrevLink &&
            <AniLink id="prev-action" fade to="/startup-visas/finland" duration={0.4}></AniLink >
        }
        {
            this.state.showNextLink &&
            <AniLink id="next-action" fade to="/startup-visas/netherlands" duration={0.4}></AniLink >
        }
    <SEO title="Denmark's Startup Visa" />
    <div id="top-part">
    <div id="back">
        <AniLink fade to="/startup-visas" duration={0.4}>
          <em>{'<-'}</em> All Startup Visas
        </AniLink>
      </div>
    <div id="contains-map">
      <div id="denmark_map_container">
      <WorldMap />
      </div>
      </div>
      <div id="left-col">
        <div id="left-col-content">
          <div className="page-icon-container" style={{fontSize:'48px'}}>
          <span role="img" aria-label="Denmark">🇩🇰</span>
          </div>
          <h1 id="country-header">Denmark's Startup Visa</h1>

        </div>
      </div>
      <div id="right-col">
      
        {/* <AniLink fade to="/interviews/estonia" className="colled">
          <div className="long-container">
            
            <div className="item-header">
            <Coffee className="slack-icon"/> 
            Interview with Program Manager
              <div className="item-arrow">
                →
              </div>
            </div>
            <div className="item-description">
            </div>

          </div>
        </AniLink> */}
        <OutboundLink className="colled"  href="https://startupdenmark.info/" target="_blank">
          <div className="external-container">
            
            <div className="item-header">
              <div className="long-icon-container">
            <Globe className="slack-icon"/> 
            </div>
            Official Website
              <div className="item-arrow">
              <ExternalLink className="slack-icon"/> 
              </div>
            </div>
            <div className="item-description">
            </div>

          </div>
        </OutboundLink>
        <OutboundLink href="https://platform.younoodle.com/client/entry-rounds/start_up_denmark/apply" target="_blank">
          <div className="external-container">
            
            <div className="item-header">
            <div className="long-icon-container">
            <Globe className="slack-icon"/> 
            </div>
            Apply Now
              <div className="item-arrow">
              <ExternalLink className="slack-icon"/> 
              </div>
            </div>
            <div className="item-description">
            </div>

          </div>
        </OutboundLink>
      
    
      </div>
    </div>
    <div className="content-container">

    <Tabs defaultActiveKey="visa-description" id="country-tabs">
    <Tab eventKey="visa-description" title="Visa Information" id="visa-information-tab">
    <p>
    Start-up Denmark is a scheme for foreign entrepreneurs giving you the opportunity to be granted a Danish residence permit in order to establish and run an innovative growth company. Your business idea must be approved by a panel of experts appointed by the Danish Business Authority before SIRI can process your application for a residence and work permit based on the scheme. The scheme can be used by both individuals and teams of up to 3 people who want to start a business together in Denmark through a joined business plan.
    </p>
      <table className="country-table">
        <tr>
          <td className="table-label">Degree Required</td>
          <td className="table-value">Not Required</td>
        </tr>
        <tr>
          <td className="table-label">Visa Duration</td>
          <td className="table-value">24 months</td>
        </tr>
          <tr>
          <td className="table-label">Bootstrappers</td>
          <td className="table-value">Yes</td>
        </tr>
          <tr>
          <td className="table-label">Visa Fees</td>
          <td className="table-value">$282</td>
        </tr>
          <tr>
          <td className="table-label">Personal Funds</td>
          <td className="table-value">$20,485</td>
        </tr>
          <tr>
          <td className="table-label">Minimum Investment</td>
          <td className="table-value">$0</td>
        </tr>
          <tr>
          <td className="table-label">Processing Time</td>
          <td className="table-value">45 days</td>
        </tr>
      </table>
    </Tab>
    <Tab eventKey="reviews" title="Founder Reviews" id="visa-information-tab">
      <div className="jobs-container">
          <div className="review-container">
              <div className="job-description">
                <p>I’ve managed to successfully immigrate to Denmark, incorporate, and start my business. It was a really smooth process that didn’t take much effort besides getting the paperwork in order. Really recommended, it’s really a great place to be and build the future.</p>
              </div>
              <div className="interview-details">
                <div className="left-details">
                <div className="job-company">
                 Ziyad Basheer
                </div>
                <div className="job-tags">
                  <div className="job-tag">
                    <div className="job-tag-text">
                      Founder
                    </div>
                  </div>
                  </div>
                </div>
                <div className="right-details">
                  <div className="job-item">
                    <div>
                      <Clock className="job-icon"/>
                    </div>
                    Processed in 15 days
                  </div>
                </div>
              </div>
          </div>
      </div>
    </Tab>
  </Tabs>
    </div>
  </Layout>
)}
      }